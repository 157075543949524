import { gql } from '@apollo/client';

// * Update Business
const UPDATE_BUSINESS_ADVERTISEMENT = gql`
  mutation Mut_Update_Business_Advertisement($input: EditBusinessAndAdvertisementByAuthStatusInputDto!) {
    editBusinessAndAdvertisementByAuthStatus(input: $input) {
      ok
      message
      error {
        message
        code
      }
    }
  }
`;

const EDIT_PROFILE_PHOTO = gql`
  mutation Mut_Edit_Profile_Photo($input: EditProfilePhotoInputDto!) {
    editProfilePhoto(input: $input) {
      error
      ok
    }
  }
`;

const GET_POINT_BY_SESSION = gql`
  query Que_Get_Point_By_Session($input: GetPointListBySessionInputDto!) {
    getPointListBySession(input: $input) {
      ok
      error
      totalCount # 총 개수
      pointList {
        id # ID
        # 구분 : pointAmount 가 양수면 적립, 음수면 사용
        reasonWhyReceived # 적립/사용내역
        amount # 적립/사용금액
        createdAt # 적립/사용일
      }
    }
  }
`;

const GET_TOTAL_POINT_AMOUNT = gql`
  query Que_Get_Total_Point_Amount {
    getTotalPointAmountBySession {
      ok
      error
      totalPointAmount
    }
  }
`;

const SEND_EMAIL_FOR_VERIFY = gql`
  mutation Mut_Send_Email_Address_Verification {
    sendEmailAddressVerificationEmail {
      ok
      error {
        code
        message
      }
    }
  }
`;

const SEND_EMAIL_FOR_DELETE = gql`
  query Que_Send_Email_For_Delete_User {
    sendEmailForDeleteUserAuthCode {
      ok
      error {
        code
        message
      }
    }
  }
`;

const SWITCH_TO_BUSINESS = gql`
  mutation Mut_Switch_To_Business($input: SwitchBusinessAccountFromPersonalInputDto!) {
    switchBusinessAccountFromPersonal(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;

const UPDATE_BUSINESS_REGISTRATION_PAPER = gql`
  mutation Mut_Update_Business_Registration_Paper($input: UpdateBusinessRegistrationPaperInputDto!) {
    updateBusinessRegistrationPaper(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;

const UPDATE_NICKNAME = gql`
  mutation Mut_Update_Nickname($input: UpdateNicknameInputDto!) {
    updateNickname(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;

const UPDATE_TEL = gql`
  mutation Mut_Update_Tel($input: UpdateTelInputDto!) {
    updateTel(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;

const UPDATE_PASSWORD = gql`
  mutation Mut_Update_User_Password_In_Mypage($input: UpdateUserPasswordInMypageInputDto!) {
    updateUserPasswordInMypage(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;

const UPDATE_USER_TYPE = gql`
  mutation Mut_Update_User_Type($input: UpdateUserTypeInputDto!) {
    updateUserType(input: $input) {
      ok
      error {
        code
        message
      }
    }
  }
`;

// * teamplan ---------------------------------------------------------------------------
const CHECK_USER_BY_EMAIL_FOR_TEAMPLAN = gql`
  query Que_Check_User_By_Email_For_Teamplan($input: CheckUserByEmailInputDto!) {
    checkUserByEmail(input: $input) {
      ok
      error
      result {
        reason
        isTeamMember
      }
    }
  }
`;

const ADD_TEAM_MEMBER = gql`
  mutation Mut_Add_Team_Member($input: AddTeamMemberInputDto!) {
    addTeamMember(input: $input) {
      ok
      error
    }
  }
`;

const GET_TEAM_MEMBER_LIST = gql`
  query Que_Get_Team_Member_List {
    getTeamMemberList {
      ok
      error
      result {
        email
      }
    }
  }
`;

const DELETE_TEAM_MEMBER = gql`
  mutation Mut_Delete_Team_Member($input: DeleteTeamMemberInputDto!) {
    deleteTeamMember(input: $input) {
      error
      ok
    }
  }
`;

// * teamplan ---------------------------------------------------------------------------

export {
  UPDATE_BUSINESS_ADVERTISEMENT,
  EDIT_PROFILE_PHOTO,
  GET_POINT_BY_SESSION,
  GET_TOTAL_POINT_AMOUNT,
  SEND_EMAIL_FOR_VERIFY,
  SEND_EMAIL_FOR_DELETE,
  SWITCH_TO_BUSINESS,
  UPDATE_BUSINESS_REGISTRATION_PAPER,
  UPDATE_NICKNAME,
  UPDATE_TEL,
  UPDATE_PASSWORD,
  UPDATE_USER_TYPE,
  CHECK_USER_BY_EMAIL_FOR_TEAMPLAN,
  ADD_TEAM_MEMBER,
  GET_TEAM_MEMBER_LIST,
  DELETE_TEAM_MEMBER,
};
