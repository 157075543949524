import { useRef, useState, useEffect } from 'react';
import imageErrorHandler from 'util/Common/ImageErrorHandler';

export type ImgProps = {
  src?: string;
  alt: string;
  width?: number | '100%';
  height?: number | '100%';
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down';
  /** lazy loading 여부 */
  lazy?: boolean;
  maxWidth?: number;
  borderRadius?: string;
  border?: string;
};
export default function LazyImage({
  src,
  alt,
  width,
  height,
  lazy = false,
  objectFit,
  maxWidth,
  borderRadius,
  border,
}: ImgProps) {
  const { elementRef } = useIsImgLoaded(lazy);

  return (
    <img
      onError={imageErrorHandler}
      ref={elementRef}
      alt={alt}
      src={src}
      style={{
        ...(width && { width }),
        ...(height && { height }),
        ...(objectFit && { objectFit }),
        ...(maxWidth && { maxWidth }),
        ...(borderRadius && { borderRadius }),
        ...(border && { border }),
      }}
    />
  );
}

export const useIsElementInViewport = (options?: IntersectionObserverInit) => {
  const elementRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const callback = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);
    elementRef.current && observer.observe(elementRef.current);

    return () => observer.disconnect();
  }, [elementRef, options]);

  return { elementRef, isVisible };
};

export const useIsImgLoaded = (lazy: boolean) => {
  const { elementRef, isVisible } = useIsElementInViewport({
    rootMargin: '0px 0px 100px 0px',
  });

  const [isLoaded, setIsLoaded] = useState(!lazy);

  useEffect(() => {
    if (isLoaded || !isVisible) {
      return;
    }

    setIsLoaded(true);
  }, [isVisible, isLoaded]);

  return { elementRef, isLoaded };
};
