import { useReactiveVar } from '@apollo/client';
import useShowProfile from 'hooks/User/useShowProfile';
import { isLoggedInVar } from 'lib/apollo/apollo';
import { useEffect } from 'react';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import useScrollToTop from 'views/Community/Board/hooks/Common/scrollToTop';

const LogisticToolsContainerAdaptive = styled.section`
  min-width: 1500px;
  width: 100%;
  min-height: 800px;
  display: flex;
  background-color: #ffffff;
  margin-left: 60px;
`;

interface LogisticToolsContainerAdaptiveLayoutProps extends RouteComponentProps {
  children: React.ReactNode;
}

function LogisticToolsContainerAdaptiveLayout({ children }: LogisticToolsContainerAdaptiveLayoutProps) {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const history = useHistory();

  useScrollToTop();

  useEffect(() => {
    if (!isLoggedIn) {
      if (history.location.pathname !== '/logistic-tools/main') {
        history.replace('/logistic-tools/main');
        return;
      }
    }
  }, [history, isLoggedIn]);

  useShowProfile();

  // useEffect(() => {
  //   if (isLoggedIn) {
  //     (async () => {
  //       await refetch();
  //     })();
  //   }
  // }, [refetch]);

  return <LogisticToolsContainerAdaptive>{children}</LogisticToolsContainerAdaptive>;
}

export default withRouter(LogisticToolsContainerAdaptiveLayout);
