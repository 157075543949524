import { useEffect } from 'react';

import MainRouter from 'router/MainRouter';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  if (process.env.NODE_ENV === 'production' && window.location.protocol === 'http:') {
    window.location.href = window.location.href.replace('http:', 'https:');
  }

  if (process.env.NODE_ENV !== 'production') {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
  }

  return <MainRouter />;
}

export default App;
