import baseStyled, { ThemedStyledInterface } from 'styled-components';
import { DefaultTheme } from 'styled-components';

// 공통 컬러
const colors = {
  // * main base green color
  baseColor: '#40b882',
  // * main white background color
  backgroundColor: '#ffffff',
  // * header background color
  headerBacgroundColor: '#2f2f35',
  // * table header border
  tableHeadBorder: '#696969',
  tableHeadBack: '#f8f8f8',
  borderColor: '#dfdfdf',
  mainFontColor: '#000',
  subFontColor: '#808080',
  infoFontColor: '#888',
  placeHolderColor: '#ddd',
  likePostCount: '#ED5133',
  // * buttons text normal color
  buttontextNavyColor: '#456580',
  // * buttons normal background color
  buttonBackgroundColor: '#f1f1f1',
  // * foucs color
  foucsColor: '#83ecab',
  redColor: '#E84C38',
  blueColor: '#2864FF',
  yellowColor: '#F9BE28',
  purpleColor: '#8F4BCD',
  subBackgroundColor: '#EFFBF5',
  grayBackgroundColor: '#F6F6F6',
  testgreenColor: '#31C9AE',
  riskDetectGreen: '#4fdc89',
  newBaseBlueColor: '#2136E8',
  newSoftBlueColor: '#5c6aea',
};

const dashboardColors = {
  base: '#56D57C',
  point: '#39CB65',
  mainFont: '#454545',
  subFont: '#848484',
  border: '#e6e6e6',
  white: '#fff',
  black: '#333',
  blue: '#4770FF',
  red: '#FF4747',
  // navy: '#456580',
  greenBackground: '#F7FFF8',
  grayBackground: '#F7F7F7',
  blueBackground: '#F5F8FF',
  redBackground: '#FFF4F4',
};

const size = {
  baseTitle: '48px',
};

const desktopWidth = {
  width: '1200px',
};
const boxShadow = {
  baseShadow: 'rgb(0 0 0 / 16%) 0px 1px 3px 0px',
};

const renewalShadow = {
  baseShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 20px',
  boardListBoxShadow: 'rgb(0 0 0 / 20%) 0px 2px 5px 0px',
};
const theme: DefaultTheme = {
  colors,
  desktopWidth,
  size,
  boxShadow,
  dashboardColors,
  renewalShadow,
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
export default theme;
